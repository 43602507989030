import {
  Component,
  Input
} from '@angular/core';
import {
  User,
  BatteryState
} from '../../routes/users/models/user';
import {
  Role
} from '../../routes/roles/models/role';
import * as _ from 'underscore';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html'
})
export class UserInfoComponent {
  @Input() user: User;
  @Input() roles: Role[];
  @Input() large = false;
  @Input() account = false;
  @Input() showLastActivity = false;
  @Input() showUserSession = false;
  @Input() showUserTeam = false;
  @Input() showUserRoles = true;
  @Input() showBattery = false;

  mapRole(ids: string[]) {
    let roles = '';
    for (let a = 0; a < ids.length; a++) {
      const index = _.findIndex(this.roles, {
        id: ids[a]
      });
      if (index >= 0) {
        roles += this.roles[index].displayName;
      } else {
        roles += 'Unkown role';
      }

      if (a !== ids.length - 1) {
        roles += ', ';
      }      
    }

    return roles;
  }

  getBatteryIcon(batteryState: BatteryState): string {
    if (batteryState !== null) {
      switch (batteryState) {
        case BatteryState.Low:
          return '/assets/images/battery-low.svg';
        case BatteryState.Medium:
          return '/assets/images/battery-medium.svg';
        case BatteryState.High:
          return '/assets/images/battery-high.svg';
      }
    }

    return '/assets/images/battery-unkown.svg';
  }
}
