import {
  Injectable,
  Inject,
  OnDestroy
} from '@angular/core';
import {
  AuthenticationHelper
} from '../../shared/helpers/utils';
import {
  AuthService
} from '../../routes/auth/services/auth.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


/**
 * When SignalR runs it will add functions to the global $ variable
 * that you use to create connections to the hub. However, in this
 * class we won't want to depend on any global variables, so this
 * class provides an abstraction away from using $ directly in here.
 */
export class SignalrWindow extends Window {
  $: any;
}

export enum ConnectionState {
  Connecting = 1,
    Connected = 2,
    Reconnecting = 3,
    Disconnected = 4
}

export class NotificationConfig {
  url: string;
  hubName: string;
  channel: string;
}

export class NotificationEvent {
  Name: NotificationTypes;
  ChannelName: NotificationChannels;
  Timestamp: Date;
  Data: any;
  Json: string;

  constructor() {
    this.Timestamp = new Date();
  }
}

export enum NotificationChannels {
  Admin = 'Admin',
    Task = 'Task',
    Help = 'Help',
    Event = 'Event',
    Location = 'Location',
    Teamstatus = 'Teamstatus',
    SystemHealth = 'SystemHealth'
}

export enum NotificationTypes {
  UserConnected = 'UserConnected',
    UserDisconnected = 'UserDisconnected',
    UserSubscribed = 'UserSubscribed',
    UserUnsubscribed = 'UserUnsubscribed',
    RoutineEvent = 'RoutineEvent',
    HelpRequest = 'HelpRequest',
    LocationUpdate = 'LocationUpdate',
    UserStatusChange = 'UserStatusChange',
    StandDown = 'StandDown'
}

class NotificationSubject {
  channel: string;
  subject: Subject < NotificationEvent > ;
}

/**
 * NotificationService is a wrapper around the functionality that SignalR
 * provides to expose the ideas of channels and events. With this service
 * you can subscribe to specific channels (or groups in signalr speak) and
 * use observables to react to specific events sent out on those channels.
 */
@Injectable()
export class NotificationService implements OnDestroy {

  /**
   * starting$ is an observable available to know if the signalr
   * connection is ready or not. On a successful connection this
   * stream will emit a value.
   */
  starting$: Observable < any > ;

  /**
   * starting$ is an observable available to know if the signalr
   * connection is ready or not. On a successful connection this
   * stream will emit a value.
   */
  stopping$: Observable < any > ;

  /**
   * connectionState$ provides the current state of the underlying
   * connection as an observable stream.
   */
  connectionState$: Observable < ConnectionState > ;

  connectionState: number;

  connectionId: string;

  /**
   * error$ provides a stream of any error messages that occur on the
   * SignalR connection
   */
  error$: Observable < string > ;

  // These are used to feed the public observables
  //
  private connectionStateSubject = new Subject < ConnectionState > ();
  private startingSubject = new Subject < any > ();
  private stoppingSubject = new Subject < any > ();
  private errorSubject = new Subject < any > ();
  private ngUnsubscribe: Subject < any > = new Subject();
  // These are used to track the internal SignalR state
  //
  private hubConnection: any;
  private hubProxy: any;

  // An internal array to track what channel subscriptions exist
  //
  private subjects = new Array < NotificationSubject > ();

  constructor(
    private authService: AuthService,
    @Inject(SignalrWindow) private window: SignalrWindow,
    @Inject('channel.config') private channelConfig: NotificationConfig
  ) {
    if (this.window.$ === undefined || this.window.$.hubConnection === undefined) {
      throw new Error("The variable '$' or the .hubConnection() function are not defined...please check the SignalR scripts have been loaded properly");
    }

    // Set up our observables
    //
    this.connectionState$ = this.connectionStateSubject.asObservable();
    this.error$ = this.errorSubject.asObservable();
    this.starting$ = this.startingSubject.asObservable();
    this.stopping$ = this.stoppingSubject.asObservable();

    this.hubConnection = this.window.$.hubConnection();

    this.hubConnection.url = channelConfig.url;
    this.hubProxy = this.hubConnection.createHubProxy(channelConfig.hubName);

    // Define handlers for the connection state events
    this.hubConnection.stateChanged((state: any) => {
      let newState = ConnectionState.Connecting;
      
      switch (state.newState) {
        case this.window.$.signalR.connectionState.connecting:
          newState = ConnectionState.Connecting;
          this.connectionState = ConnectionState.Connecting;
          break;
        case this.window.$.signalR.connectionState.connected:
          newState = ConnectionState.Connected;
          this.connectionState = ConnectionState.Connected;
          break;
        case this.window.$.signalR.connectionState.reconnecting:
          newState = ConnectionState.Reconnecting;
          this.connectionState = ConnectionState.Reconnecting;
          break;
        case this.window.$.signalR.connectionState.disconnected:
          newState = ConnectionState.Disconnected;
          this.connectionState = ConnectionState.Disconnected;
          break;
      }

      // Push the new state on our subject
      this.connectionStateSubject.next(newState);
    });

    this.authService.tokenRefreshed.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.hubConnection.stop();
    });

    this.authService.tokenExpired.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      if (!this.authService.processing) {
        this.authService.processing = true;
        this.authService.refreshToken().pipe(takeUntil(this.ngUnsubscribe)).subscribe((newToken) => {
          this.authService.publish(newToken);
          this.authService.processing = false;
        });
      }
    });

    // Define discconnection handler
    this.hubConnection.disconnected(() => {
      console.log('Hub disconnected');
      // Hub disconnected, keep retrying every five seconds.
      if (AuthenticationHelper.getState().loggedIn === true) {

        setTimeout(() => {
          // Add auth token to request headers.
          const authToken = AuthenticationHelper.getBearerToken();

          if (authToken !== null && authToken.access_token !== '') {
            console.log('Updating auth token');
            this.hubConnection.qs = {
              'token': authToken.access_token
            };
          }
          console.log('trying to reconnect');
          this.hubConnection.start()
            .done(() => {
              console.log('hub reconnected');
              // need to resubscribe to channels when we reestablish a connection..
              console.log(this.subjects);
              this.subjects.forEach((subject: NotificationSubject) => {
                console.log('restart subject' + subject.channel);
                this.sub(subject.channel);
              });
              this.startingSubject.next();
            })
            .fail((error: any) => {
               console.log('Unable to start connection');
              //console.log(error);
              //this.startingSubject.error(error);
            });
        }, 5000); // retry every five seconds.
      }
    });

    this.hubConnection.reconnected(() => {
      console.log('hub reconnected');
      // need to resubscribe to channels when we reestablish a connection..
      console.log(this.subjects);
      this.subjects.forEach((subject: NotificationSubject) => {
        console.log('restart subject' + subject.channel);
        this.sub(subject.channel);
      });
    });

    // Define handlers for any errors
    this.hubConnection.error((error: any) => {
      // Push the error on our subject
      //
      this.errorSubject.next(error);
    });

    this.hubProxy.on('onEvent', (channel: string, ev: NotificationEvent) => {

      // This method acts like a broker for incoming messages. We
      //  check the interal array of subjects to see if one exists
      //  for the channel this came in on, and then emit the event
      //  on it. Otherwise we ignore the message.
      const channelSub = this.subjects.find((x: NotificationSubject) => {
        return x.channel === channel;
      }) as NotificationSubject;

      // If we found a subject then emit the event on it
      //
      if (channelSub !== undefined) {
        return channelSub.subject.next(ev);
      }
    });

  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

getConnectionId(): string {
  return this.hubConnection.id;
}

  /**
   * Start the SignalR connection. The starting$ stream will emit an
   * event if the connection is established, otherwise it will emit an
   * error.
   */
  start(): void {
    // Now we only want the connection started once, so we have a special
    //  starting$ observable that clients can subscribe to know know if
    //  if the startup sequence is done.
    //
    // If we just mapped the start() promise to an observable, then any time
    //  a client subscried to it the start sequence would be triggered
    //  again since it's a cold observable.
    //

    const authToken = AuthenticationHelper.getBearerToken();

    if (authToken !== null && authToken.access_token !== '') {
      this.hubConnection.qs = {
        'token': authToken.access_token
      };
    }

    this.hubConnection.start()
      .done(() => {
        this.startingSubject.next();
      })
      .fail((error: any) => {
        //console.log(error);
        //this.startingSubject.error(error);
      });


  }

  stop(): void {
    this.hubConnection.stop();

    this.subjects.forEach(subject => {
      subject.subject.unsubscribe();
    });

    this.subjects.length = 0;

    this.stoppingSubject.next();
  }

  /**
   * Get an observable that will contain the data associated with a specific
   * channel
   * */
  sub(channel: string): Observable < NotificationEvent > {

    // Try to find an observable that we already created for the requested
    //  channel
    //
    let channelSub = this.subjects.find((x: NotificationSubject) => {
      return x.channel === channel;
    }) as NotificationSubject;

    // If we already have one for this event, then just return it
    //
    if (channelSub !== undefined) {
      console.log(`Found existing observable for ${channel} channel`);
      return channelSub.subject.asObservable();
    }

    //
    // If we're here then we don't already have the observable to provide the
    //  caller, so we need to call the server method to join the channel
    //  and then create an observable that the caller can use to received
    //  messages.
    //

    // Now we just create our internal object so we can track this subject
    //  in case someone else wants it too
    //
    channelSub = new NotificationSubject();
    channelSub.channel = channel;
    channelSub.subject = new Subject < NotificationEvent > ();
    this.subjects.push(channelSub);

    //  Now SignalR is asynchronous, so we need to ensure the connection is
    //  established before we call any server methods. So we'll subscribe to
    //  the starting$ stream since that won't emit a value until the connection
    //  is ready. Unless we already know the connection state is connected.
    //

    if (this.connectionState === ConnectionState.Connected) {
      this.hubProxy.invoke('Subscribe', channel)
        .done(() => {
          console.log(`Successfully subscribed to ${channel} channel`);
        })
        .fail((error: any) => {
          channelSub.subject.error(error);
        });
    } else {

      this.starting$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
          this.hubProxy.invoke('Subscribe', channel)
            .done(() => {
              console.log(`Successfully subscribed to ${channel} channel`);
            })
            .fail((error: any) => {
              channelSub.subject.error(error);
            });
        },
        (error: any) => {
          channelSub.subject.error(error);
        });
    }

    return channelSub.subject.asObservable();
  }

  // Not quite sure how to handle this (if at all) since there could be
  //  more than 1 caller subscribed to an observable we created
  //
  // unsubscribe(channel: string): Rx.Observable<any> {
  //     this.observables = this.observables.filter((x: ChannelObservable) => {
  //         return x.channel === channel;
  //     });
  // }

  /** publish provides a way for calles to emit events on any channel. In a
   * production app the server would ensure that only authorized clients can
   * actually emit the message, but here we're not concerned about that.
   */
  publish(ev: NotificationEvent): void {
    this.hubProxy.invoke('Publish', ev);
  }

}
