import {
  RouterStateSerializer
} from '@ngrx/router-store';
import {
  RouterStateSnapshot,
  Params
} from '@angular/router';
import * as fromAuth from '../../core/store/reducers/auth';
import {
  User
} from '../../routes/users/models/user';
import { AuthToken } from '../../routes/auth/models/token';
import * as moment from 'moment';
import { Role, MenuItem } from '../../routes/roles/models/role';
import { NavItem } from '../../core/models/nav';
import { SidePanelService } from '../../core/services/sidepanel.service';

/**
 * The RouterStateSerializer takes the current RouterStateSnapshot
 * and returns any pertinent information needed. The snapshot contains
 * all information about the state of the router at the given point in time.
 * The entire snapshot is complex and not always needed. In this case, you only
 * need the URL and query parameters from the snapshot in the store. Other items could be
 * returned such as route parameters and static route data.
 */

export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';


export interface RouterStateUrl {
  url: string;
  queryParams: Params;
}

export class CustomRouterStateSerializer
implements RouterStateSerializer < RouterStateUrl > {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    const {
      url
    } = routerState;
    const queryParams = routerState.root.queryParams;
    return {
      url,
      queryParams
    };
  }
}

export class AuthenticationHelper {
  static menuKey = "storage_menu";

  static getState(): fromAuth.State {
    const authToken = <AuthToken> JSON.parse(localStorage.getItem(fromAuth.authCookie));
    return {
      ...fromAuth.initialState,
      loggedIn: (authToken != null),
      authToken: authToken,
      user: this.getCurrentUser()
    };
  }

  static hasAuthorization(): boolean {
    const newToken = AuthenticationHelper.getBearerToken();
    if (newToken == null || newToken.access_token === '') {
      return false;
    }
    const now = moment();
    const expiresOnUtc = moment(newToken.expires_on).utc();
    const expiresAt = expiresOnUtc.local();

    if (expiresAt.isBefore(now)) {
      return false;
    }

    return true;
  }

  static hasAuthorizationRefresh(): boolean {
    const newToken = AuthenticationHelper.getBearerToken();
    return (newToken !== null && newToken.refresh_token !== null && newToken.refresh_token !== undefined);
  }


  static hasMenuForRoute(route: string): boolean {
    const menus = this.getMenus();
    let menu = null;
    
    menus.forEach((m) => {
      if(m.url == route) {
        menu = m;
      }

      m.children.forEach((c) => {
        if(c.url == route) {
          menu = c;
        }
      })
    });

    return menu != null;
  }

  static hasMenuItems(): boolean {
    const menus = this.getMenus();
    if(menus !== null && menus !== undefined) {
      return true;
    }

    return false;
  }

  static getMenuForRoute(route: string): NavItem {
    const menus = this.getMenus();
    let menu = null;
    
    if(menus !== null && menus !== undefined) {
      menus.forEach((m) => {
        if(m.url == route) {
          menu = m;
        }
  
        m.children.forEach((c) => {
          if(c.url == route) {
            menu = c;
          }
        })
      });
    }

    return menu;
  }

  static getMenuById(id: number): NavItem {
    const menus = this.getMenus();
    let menu = null;
    
    menus.forEach((m) => {
      if(m.id === id) {
        menu = m;
      }

      m.children.forEach((c) => {
        if(c.id === id) {
          menu = c;
        }
      })
    });

    return menu;
  }

  static getMenus(): NavItem[] {
    const menus = <NavItem[]>JSON.parse(localStorage.getItem(this.menuKey));
    return menus;
  }

  static saveMenu(menus: NavItem[]){
      localStorage.setItem(this.menuKey, JSON.stringify(menus));
  }

  static getBearerToken(): AuthToken {
    const authToken = <AuthToken> JSON.parse(localStorage.getItem(fromAuth.authCookie));
    return authToken;
  }

  static setBearerToken(bearerToken: AuthToken) {
    localStorage.setItem(fromAuth.authCookie, JSON.stringify(bearerToken));
  }

  static getCurrentUser(): User {
    const currentUser = < User > JSON.parse(localStorage.getItem('currentUser'));
    return currentUser;
  }

  static SaveAllRoles(roles: Role[]) {
    localStorage.setItem('storage_all_roles', JSON.stringify(roles));
  }

  static GetAllRoles(): Role[] {
    const roles = <Role[]> JSON.parse(localStorage.getItem('storage_all_roles'));
    return roles;
  }

  static StoreUserRoles(roles: string[]) {
    localStorage.setItem('storage_all_user_roles', JSON.stringify(roles));
  }

  static IsOnBoarderNew(): boolean {

    const json = localStorage.getItem('storage_all_user_roles');

    if (json !== null && json !== undefined) {
      const roles = <string[]> JSON.parse(json);

      const onboarderRole = roles.find(x => x === 'MDM Onboarder');
      
      if (onboarderRole !== null && onboarderRole !== undefined) {
        return true;
      }
    }

    return false;
  }

  static IsUserInRole(roleName: string, userRoles: string[]) {
   const roles = AuthenticationHelper.GetAllRoles();

   if (roles !== null && roles !== undefined) {
     const role = roles.find(x => x.name === roleName);

     if (role !== null && role !== undefined) {
        const userRole = userRoles.find(x => x === role.id);

        return userRole != null && userRole !== undefined;
     }
   }

    return false;
  }

  static IsInRole(roleName: string): boolean {
    const json = localStorage.getItem('storage_all_user_roles');

    if (json !== null && json !== undefined) {
      const roles = <string[]> JSON.parse(json);

      const onboarderRole = roles.find(x => x === roleName);
      
      if (onboarderRole !== null && onboarderRole !== undefined) {
        return true;
      }
    }

    return false;
  }

  static IsVendorAdmin(): boolean {
    const json = localStorage.getItem('storage_all_user_roles');

    if (json !== null && json !== undefined) {
      const roles = <string[]> JSON.parse(json);

      const onboarderRole = roles.find(x => x === 'Vendor Administrator');
      
      if (onboarderRole !== null && onboarderRole !== undefined) {
        return true;
      }
    }
    return false;
  }

  static IsAdmin(): boolean {
    const json = localStorage.getItem('storage_all_user_roles');

    if (json !== null && json !== undefined) {
      const roles = <string[]> JSON.parse(json);

      const onboarderRole = roles.find(x => x === 'Administrator');
      
      if (onboarderRole !== null && onboarderRole !== undefined) {
        return true;
      }
    }

    return false;
  }

  static IsTeamManager(): boolean {
    const json = localStorage.getItem('storage_all_user_roles');

    if (json !== null && json !== undefined) {
      const roles = <string[]> JSON.parse(json);

      const onboarderRole = roles.find(x => x === 'Team Manager');
      
      if (onboarderRole !== null && onboarderRole !== undefined) {
        return true;
      }
    }

    return false;
  }

  static IsOnboarder(roles: Role[]): boolean {
    const user = this.getCurrentUser();

    if (user === null || user === undefined) {
      return false;
    }

    if (roles === null || roles === undefined) {
      return false;
    }

    const onboarderRole = roles.find(x => x.name === 'Onboarder');

    if (onboarderRole !== null && onboarderRole !== undefined) {
      const userRole = user.roles.find(x => x === onboarderRole.id);

      if (userRole === null || userRole === undefined) {
        return false;
      } else {
        return true;
      }
    }

    return false;
  }

  static getUserRoles(): string[] {
    const user = this.getCurrentUser();
    const roles = new Array<string>();
    if (user == null || user === undefined) {
      return new Array<string>();
    }

    user.roles.map((role: string) => {
      roles.push(role);
    });
    return roles;
  }

  static setCurrentUser(user) {
    localStorage.setItem('currentUser', JSON.stringify(user));
  }

  static clearLocalStorage() {
    localStorage.clear();
  }
}

export class ErrorHelper {
  static GetErrorMessage(error: any): string {
    if (error.error) {
      return error.error.Message;
    } 
    return '';
  }
}

export class GeneralHelper {

  static copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      console.log('Text copied to clipboard!');
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };
}
