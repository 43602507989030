import {
  Component,
  Input,
  OnInit,
  NgZone,
  OnDestroy,
  ChangeDetectorRef,
  ViewChild,
  Output,
  EventEmitter,
  Injector,
  ChangeDetectionStrategy,
  AfterViewInit,
  ElementRef
} from '@angular/core';
import {
  Router
} from '@angular/router';
import {
  NavItem
} from '../../../../core/models/nav';
import {
  NotificationService,
  NotificationEvent,
  NotificationChannels,
  NotificationTypes,
  ConnectionState
} from '../../../../core/services/notification.service';
import {
  SidePanelService
} from '../../../../core/services/sidepanel.service';
import { HelpToastComponent } from '../../help-toast.component';
import { ToastContainerDirective, ToastrService, GlobalConfig, Toast } from 'ngx-toastr';
import { Nudge, User } from '../../../../routes/users/models/user';
import { AuthenticationHelper } from '../../../../shared/helpers/utils';
import { Role } from '../../../../routes/roles/models/role';
import * as _ from 'underscore';
import { SettingsStorageService } from '../../../../core/services/settings.service';
const screenfull = require('screenfull');
const browser = require('jquery.browser');
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfigService } from '../../../../core/services/config.service';
import { environment } from '../../../../../environments/environment';
import { MenuService } from '../../../../core/services/menu.service';
import { StandDownToastComponent } from '../../standdown-toast.component';
declare var $: any;

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styles: [`
    .content-panels.full-width {
      padding: 0 !important;
    }
  `],
  changeDetection: ChangeDetectionStrategy.Default
})
export class SideNavComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() loggedIn: boolean;
  @Input() navItems: NavItem[];
  @Input() hasRightPanel = false;
  @Input() connectionState: string;
  @Output() logout = new EventEmitter();
  @Input() roles: Role[] = [];
  @Input() user: User | null;
  @Input() testMode: boolean;
  @Input() version: string;
  @ViewChild('fsbutton',{static: false}) fsbutton;  // the fullscreen button
  sidePanelMode: number;
  sidePanelId: string;
  nudges: Nudge[];
  sidePanelCloseOnUrlChange: boolean;
  canReceiveHelpRequests: boolean = true;
  notificationCount = 0;
  messages = '';
  lastUl: any;
  private warningSound: HTMLAudioElement;
  options: GlobalConfig;
  @ViewChild('app-help-toast-component', {static: false}) containerElement;
  @ViewChild(ToastContainerDirective, {static: true}) toastContainer: ToastContainerDirective;
  private ngUnsubscribe: Subject<any> = new Subject();
  signalRConnectionState: ConnectionState;
  $doc: any = null;
  sbclickEvent = 'click.sidebar-toggle';
  isOnboarder: boolean = false;
  isSmallScreen: boolean = false;
  @ViewChild('mainNav',{static: false}) mainNavElement!: ElementRef;

  constructor(private menuService: MenuService, private cd: ChangeDetectorRef, private injector: Injector, private settingsService: SettingsStorageService, public toastService: ToastrService, private router: Router, private notificationService: NotificationService, private zone: NgZone, private sidePanelService: SidePanelService) {
    
  }

  ngOnInit() {    
    this.options = this.toastService.toastrConfig;
    this.warningSound = new Audio();
    this.warningSound.src = '../../../../assets/audio/sos.mp3';
    this.warningSound.load();
    this.user = AuthenticationHelper.getCurrentUser();
    this.isOnboarder = AuthenticationHelper.IsOnBoarderNew();

    this.sidePanelService.OpenSidePanelForSettings.pipe(takeUntil(this.ngUnsubscribe)).subscribe((id) => {
      if (this.hasRightPanel) {
        this.resetSidePanel();
        this.hasRightPanel = false;
      } else {
        this.sidePanelMode = 1;
        this.hasRightPanel = true;
        this.sidePanelCloseOnUrlChange = true;
      }
    });

    this.sidePanelService.OpenSidePanelForLocation.pipe(takeUntil(this.ngUnsubscribe)).subscribe((id) => {
      if (this.hasRightPanel) {
        if (id !== this.sidePanelId) {
          this.sidePanelMode = 3;
          this.sidePanelId = id;
          this.hasRightPanel = true;
          this.sidePanelCloseOnUrlChange = true;
        } else {
          this.resetSidePanel();
          this.hasRightPanel = false;
        }
      } else {
        this.sidePanelMode = 3;
        this.sidePanelId = id;
        this.hasRightPanel = true;
        this.sidePanelCloseOnUrlChange = true;
      }
    });

    this.sidePanelService.OpenSidePanelForHelpDetails.pipe(takeUntil(this.ngUnsubscribe)).subscribe((id) => {
      if (this.hasRightPanel) {
        if (id !== this.sidePanelId) {
          this.sidePanelMode = 2;
          this.sidePanelId = id;
          this.hasRightPanel = true;
        } else {
          this.resetSidePanel();
          this.hasRightPanel = false;
        }
      } else {
        this.sidePanelMode = 2;
        this.sidePanelId = id;
        this.hasRightPanel = true;
      }
    });

    this.sidePanelService.OpenSidePanelForUser.pipe(takeUntil(this.ngUnsubscribe)).subscribe((id) => {
      if (this.hasRightPanel) {
        if (id !== this.sidePanelId) {
          this.sidePanelMode = 4;
          this.sidePanelId = id;
          this.hasRightPanel = true;
          this.sidePanelCloseOnUrlChange = true;
        } else {
          this.resetSidePanel();
          this.hasRightPanel = false;
        }
      } else {
        this.sidePanelMode = 4;
        this.sidePanelId = id;
        this.hasRightPanel = true;
        this.sidePanelCloseOnUrlChange = true;
      }
    });

    this.sidePanelService.OpenSidePanelForCostEstimate.pipe(takeUntil(this.ngUnsubscribe)).subscribe((id) => {
      if (this.hasRightPanel) {
        this.resetSidePanel();
        this.hasRightPanel = false;
      } else {
        this.sidePanelMode = 5;
        this.hasRightPanel = true;
        this.sidePanelCloseOnUrlChange = true;
      }
    });

    this.sidePanelService.OpenSidePanelForCarbonFootprintDetails.pipe(takeUntil(this.ngUnsubscribe)).subscribe((id) => {
      if (this.hasRightPanel) {
        this.resetSidePanel();
        this.hasRightPanel = false;
      } else {
        this.sidePanelMode = 6;
        this.hasRightPanel = true;
        this.sidePanelCloseOnUrlChange = true;
      }
    });

    this.sidePanelService.OpenSidePanelForGlobalUsers.pipe(takeUntil(this.ngUnsubscribe)).subscribe((id) => {
      if (this.hasRightPanel) {
        this.resetSidePanel();
        this.hasRightPanel = false;
      } else {
        this.sidePanelMode = 7;
        this.sidePanelId = id;
        this.hasRightPanel = true;
        this.sidePanelCloseOnUrlChange = true;
      }
    });

    this.sidePanelService.OpenSidePanelForNotes.pipe(takeUntil(this.ngUnsubscribe)).subscribe((id) => {
      if (this.hasRightPanel) {
        this.resetSidePanel();
        this.hasRightPanel = false;
      } else {
        this.sidePanelMode = 8;
        this.sidePanelId = id.helpMessageId;
        this.hasRightPanel = true;
        this.sidePanelCloseOnUrlChange = true;
      }
    });

    this.sidePanelService.OpenSidePanelForNudges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((nudge) => {
      if (this.hasRightPanel) {
        this.resetSidePanel();
        this.hasRightPanel = false;
      } else {
        this.sidePanelMode = 9;
        this.nudges = nudge;
        this.hasRightPanel = true;
        this.sidePanelCloseOnUrlChange = true;
      }
    });

    this.sidePanelService.CloseSidePanel.pipe(takeUntil(this.ngUnsubscribe)).subscribe((x) => {
      if(this.hasRightPanel) {
        this.resetSidePanel();
        this.hasRightPanel = false;
      }
    });

    // Bind the notifcation container.
    this.toastService.overlayContainer = this.toastContainer;


    this.router = this.injector.get(Router);

    this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe((val) => {
        if (this.hasRightPanel && this.sidePanelCloseOnUrlChange) {
          this.resetSidePanel();
          this.hasRightPanel = false;
        }

        // close any submenu opened when route changes
        this.removeFloatingNav();
        // scroll view to top
        window.scrollTo(0, 0);
        // close sidebar on route change
        this.settingsService.setLayoutSetting('asideToggled', false);
    });

    this.notificationService.connectionState$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((state) => {
      this.signalRConnectionState = state;
      if (!this.cd['destroyed']) {
        this.cd.detectChanges();
      }
    });

    if ((this.user !== null && this.user !== undefined)) {
        if (!this.isOnboarder) {
          // Get an observable for events emitted on this channel, if we have the required role.
          //
          this.notificationService.sub(NotificationChannels.Help).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
            (x: NotificationEvent) => {
              console.log("alert event type:" + x.Name);
              switch (x.Name) {
                case NotificationTypes.HelpRequest:
                  {
                    this.helpRequestAlert(x);
                    break;
                  }
                  case NotificationTypes.StandDown:
                  {
                    this.standDownAlert(x);
                    break;
                  }
              }
            },
            (error: any) => {
              console.warn('Attempt to join channel failed!', error);
            }
          );          
        }
    }
    
  }

  ngAfterViewInit() {
    if (browser.msie) { // Not supported under IE
      this.fsbutton.nativeElement.style.display = 'none';
    }

    this.checkScreenWidth();
  }

  ngOnDestory() {

    if (this.$doc) {
      this.$doc.off(this.sbclickEvent);
    }

    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.cd.detach();
  }

  getConnectionState(): string {
    let result = '';

    switch (this.signalRConnectionState) {
        case ConnectionState.Connected:
            result = 'connection-status connection-status-sucess out';
        break;
        case ConnectionState.Connecting:
            result = 'connection-status connection-status-warning in';
        break;
        case ConnectionState.Reconnecting:
            result = 'connection-status connection-status-warning in';
        break;
        case ConnectionState.Disconnected:
            result = 'connection-status connection-status-danger in';
        break;
    }

    return result;
  }

  getConnectionStateDescription(): string {
    let result = '';

    switch (this.signalRConnectionState) {
        case ConnectionState.Connected:
            result = 'Connected';
        break;
        case ConnectionState.Connecting:
            result = 'Connecting';
        break;
        case ConnectionState.Reconnecting:
            result = 'Reconnecting';
        break;
        case ConnectionState.Disconnected:
            result = 'Disconnected';
        break;
    }

    return result;
  }

  private checkScreenWidth(): void {
    this.isSmallScreen = window.innerWidth < 720;
    if (this.isSmallScreen) {
      this.settingsService.setLayoutSetting('isCollapsed', true);      
      if(this.mainNavElement){
        const mainNav = this.mainNavElement.nativeElement;
        if (mainNav.classList.contains('expanded')) {
          mainNav.classList.toggle('expanded');
        } 
      }      
      
    }
  }

  toggleSideBar() {
    this.settingsService.toggleLayoutSetting('isCollapsed');
  }

  onClick() {
    this.toastService.success('in div');
  }

  onRouteSelected(id: number) {
    this.menuService.currentRouteId = id;
  }

  mapRole(ids: string[]) {
    let roles = '';
    for (let a = 0; a < ids.length; a++) {
      const index = _.findIndex(this.roles, {
        id: ids[a]
      });
      if (index >= 0) {
        roles += this.roles[index].displayName;
      } else {
        roles += 'Unkown role';
      }

      if (a !== ids.length - 1) {
        roles += ', ';
      }
    }

    return roles;
  }

  logoutClick() {
    this.logout.emit();
  }

  anyClickClose() {
    this.$doc = $(document).on(this.sbclickEvent, (e) => {
      if (!$(e.target).parents('.aside-container').length) {
          this.settingsService.setLayoutSetting('asideToggled', false);
      }
  });
  }

  toggleSubmenuClick(event) {

    event.preventDefault();

    if (!this.isSidebarCollapsed()) {

        let target = $(event.target || event.srcElement || event.currentTarget);
        let ul, anchor = target;

        // find the UL
        if (!target.is('a')) {
            anchor = target.parent('a').first();
        }
        ul = anchor.next();
        this.lastUl = ul;
        // hide other submenus
        let parentNav = ul.parents('.sidebar-subnav');
        $('.sidebar-subnav').each((idx, el) => {
            let $el = $(el);
            // if element is not a parent or self ul
            if (!$el.is(parentNav) && !$el.is(ul)) {
                this.closeMenu($el);
            }
        });

        // abort if not UL to process
        if (!ul.length) {
            return;
        }

        // any child menu should start closed
        ul.find('.sidebar-subnav').each((idx, el) => {
            this.closeMenu($(el));
        });

        // toggle UL height
        if (parseInt(ul.height(), 0)) {
            this.closeMenu(ul);
        }
        else {
            // expand menu
            ul.on('transitionend', () => {
                ul.height('auto').off('transitionend');
            }).height(ul[0].scrollHeight);
            // add class to manage animation
            ul.addClass('opening');
        }

    }

}

// Close menu collapsing height
closeMenu(elem) {
  elem.css('height', elem[0].scrollHeight); // set height
  elem.css('height', 0); // and move to zero to collapse
  elem.removeClass('opening');
}

  toggleSubmenuHover(event) {
    const self = this;
    if (this.isSidebarCollapsed()) {
        event.preventDefault();

        this.removeFloatingNav();

        let target = $(event.target || event.srcElement || event.currentTarget);
        let ul, anchor = target;
        // find the UL
        if (!target.is('a')) {
            anchor = target.parent('a');
        }
        ul = anchor.next();
        this.lastUl = ul;

        if (!ul.length) {
            return; // if not submenu return
        }

        let $aside = $('.aside-container');
        let $asideInner = $aside.children('.content-wrap'); // for top offset calculation
        let $sidebar = $asideInner.children('.main-nav');
        let $logo = $sidebar.children('.logo');
        let $account = $sidebar.children('.account');
        let mar = parseInt($asideInner.css('padding-top'), 0) + parseInt($asideInner.css('padding-top'), 0) + parseInt($aside.css('padding-top'), 0) + parseInt($logo.css('margin-bottom'), 0) + parseInt($logo.css('padding-bottom'), 0) + parseInt($account.css('margin-bottom'), 0);
        let itemTop = ((anchor.parent().position().top) + mar + $logo.position().top + $account.position().top + anchor.position().top) - $sidebar.scrollTop();
        let floatingNav = ul.clone().appendTo($aside);
        let vwHeight = $(window).height();

        // get padding for each li.
        const $links = $('.nav-tabs li');
        const $current = anchor.parent();
        const index = $links.index($current) - 1;

        itemTop += (parseInt($current.css('margin-bottom'), 0) * index );
        itemTop -= 24;

        floatingNav.children('li').first().children( '.sub-title').css({
          display: 'list-item',
          height: $current.height()
        });

        ul.parent().addClass('open');
        // let itemTop = anchor.position().top || anchor.offset().top;

        // each item has ~60px height
        // multiply to force space for at least N items
        const safeOffsetValue = (60 * 5);
        const navHeight = floatingNav.height() + 2; // 2px border
        const safeOffset = navHeight < safeOffsetValue ? navHeight : safeOffsetValue;

        const displacement = 25; // displacement in px from bottom

        // if not enough space to show N items, use then calculated 'safeOffset'
        const menuTop = (vwHeight - itemTop > safeOffset) ? itemTop : (vwHeight - safeOffset - displacement);

        floatingNav
            .removeClass('opening') // necesary for demo if switched between normal//collapsed mode
            .addClass('nav-floating')
            .css({
                position: this.settingsService.getLayoutSetting('isFixed') ? 'fixed' : 'absolute',
                top: itemTop,
                bottom: (floatingNav.outerHeight(true) + menuTop > vwHeight) ? (displacement+'px') : 'auto',
                left: $sidebar.width()
            });

        floatingNav
            .on('mouseleave', () => {
              this.removeFloatingNav();
            })
            .find('a').on('click', function(e) {
                e.preventDefault(); // prevents page reload on click
                // get the exact route path to navigate
                let routeTo = $(this).attr('route');
                if (routeTo) self.router.navigate([routeTo]);
            });

        this.listenForExternalClicks(ul);

    }

}

listenForExternalClicks(ul) {
  let $doc = $(document).on('click.sidebar', (e) => {
      if (!$(e.target).parents('.aside-container').length) {
          this.removeFloatingNav();
          $doc.off('click.sidebar');
      }
  });
}

expandNav(event: any) {
  const isExpandable = $(event.currentTarget).parent().hasClass('expandable');
  if (isExpandable) {
    $(event.currentTarget).parent().toggleClass('expanded');
  }
}

isSidebarCollapsed() {
  return this.settingsService.getLayoutSetting('isCollapsed');
}

  settingsClick() {
    this.sidePanelService.OpenSidePanelForSettings.emit("1");
  }

  removeFloatingNav() {
    if (this.lastUl !== undefined) {
      this.lastUl.parent().removeClass('open');
    }
    $('.nav-floating').remove();
}

  toggleFullScreen(event) {

    if (screenfull.enabled) {
        screenfull.toggle();
    }
    // Switch icon indicator
    const el = $(this.fsbutton.nativeElement);
    if (screenfull.isFullscreen) {
        el.children('em').removeClass('fa-expand').addClass('fa-compress');
    } else {
        el.children('em').removeClass('fa-compress').addClass('fa-expand');
    }
  }

  resetSidePanel() {    
      this.sidePanelMode = 0;
      this.hasRightPanel = false;
      this.sidePanelCloseOnUrlChange = false;      
  }

  ngOnDestroy() {
    if (this.warningSound) {
      this.warningSound.pause();
      this.warningSound = null;
    }
  }

  helpRequestAlert(request: NotificationEvent) {
    if (this.loggedIn) {
      const data = JSON.parse(request.Json);
      this.zone.run(() => {
        if (this.warningSound !== null) {
          this.warningSound.play();
        }
        
        const inserted = this.toastService.show(data.Message, data.UserName, {
          closeButton: true,
          enableHtml: true,
          toastComponent: HelpToastComponent,
          disableTimeOut: true,
          tapToDismiss: false,
          onActivateTick: true
        });

        inserted.toastRef.componentInstance.UserId = data.UserId;
        inserted.toastRef.componentInstance.HelpMessageId = data.HelpMessageId;
        inserted.onShown.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
          this.zone.run(() => {
            var hasChild = this.toastContainer.getContainerElement().getElementsByClassName('toast-container').length > 0;
            this.cd.detectChanges();
          });
        });
        inserted.onHidden.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
          setTimeout(() => {
            this.zone.run(() => {
              this.cd.detectChanges();
            });
          });
        });
      });
    }
  }

  standDownAlert(request: NotificationEvent) {
    if (this.loggedIn) {
      const data = JSON.parse(request.Json);
      this.zone.run(() => {        
        
        const inserted = this.toastService.show(data.Message, data.UserName, {
          closeButton: true,
          enableHtml: true,
          toastComponent: StandDownToastComponent,
          disableTimeOut: true,
          tapToDismiss: false,
          onActivateTick: true
        });

        inserted.toastRef.componentInstance.UserId = data.UserId;
        console.log(data);
        console.log("helpid: " + data.HelpMessageId);
        inserted.toastRef.componentInstance.HelpMessageId = data.HelpMessageId;
        inserted.onShown.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
          this.zone.run(() => {
            var hasChild = this.toastContainer.getContainerElement().getElementsByClassName('toast-container').length > 0;
            this.cd.detectChanges();
          });
        });
        inserted.onHidden.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
          setTimeout(() => {
            this.zone.run(() => {
              this.cd.detectChanges();
            });
          });
        });
      });
    }
  }



  onActivate(event: any) {
    this.hasRightPanel = true;
  }

  onDeactivate(event: any) {
    this.hasRightPanel = false;
  }
}
